import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPodcasts(ctx, queryParams) {
      console.log('PODCAST')
      const { limit } = queryParams
      const { page } = queryParams
      const { q } = queryParams

      const uri = `/radio/admin/podcast?page=${page}&limit=${limit}&q=${q}`

      return new Promise((resolve, reject) => {
        axios
          .get(uri)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
    fetchPodcast(ctx, id) {
      const uri = `/radio/admin/podcast/${id}`

      return new Promise((resolve, reject) => {
        axios
          .get(uri)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
    updatePodcast(ctx, payload) {
      const uri = '/radio/admin/podcast'

      return new Promise((resolve, reject) => {
        axios
          .put(uri, payload)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
    createPodcast(ctx, payload) {
      const uri = '/radio/admin/podcast'

      return new Promise((resolve, reject) => {
        axios
          .post(uri, payload)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
    deletePodcast(ctx, id) {
      const uri = `/radio/admin/podcast?id=${id}`

      return new Promise((resolve, reject) => {
        axios
          .delete(uri)
          .then(response => resolve(response.data ? response.data.data : {}))
          .catch(error => reject(error))
      })
    },
  },
}
