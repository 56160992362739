<template>
  <div>
    <b-card>
      <div class="border rounded p-2">
        <h4 class="mb-1">
          Profile Image
        </h4>
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-img
                ref="previewEl"
                rounded
                :src="podcast.imageUrl "
                height="250"
                alt=""
                width="250"
              />
            </b-link>
          <!--/ avatar -->
          </b-media-aside>
          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button
              v-model="file"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              :state="Boolean(file)"
              @click="$refs.refInputEl.$el.click()"
            >
              Upload
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="profileFile"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              @input="fileSelected"
            />
            <!--/ upload button -->

            <!-- reset -->

            <!--/ reset -->
            <b-card-text>Allowed JPG, GIF or PNG. Max size of 2 MB</b-card-text>
          </b-media-body>
        </b-media>
      </div>
      <b-form-group
        label="Title"
        label-for="name"
        class="my-2"
      >
        <b-form-input
          id="name"
          v-model="podcast.title"
        />

      </b-form-group>
      <b-form-group
        label="Description"
        label-for="name"
        class="my-2"
      >
        <b-form-input
          id="name"
          v-model="podcast.description"
        />

      </b-form-group>
      <b-form-group
        label="Podcast Url"
        label-for="name"
        class="my-2"
      >
        <b-form-input
          id="name"
          v-model="podcast.podcastUrl"
        />

      </b-form-group>
      <b-row v-if="presenters.length > 0">
        <b-col cols="12">
          <div class="text-center my-1">
            <h3><b>Select a Presenter</b></h3>
          </div>
        </b-col>
        <b-col
          v-for="item in presenters"
          :key="item.id"
          cols="12"
          md="3"
        >
          <b-card
            :border-variant="programmerIds.includes(item.id) ? 'primary' : 'secondary'"
            class="text-center cursor-pointer h-100"
            @click="addPresenterIfNotExist(item.id)"
          >
            <b-card-text>
              <img
                :src="item.profileImage"
                height="40"
                alt=""
              >
              <div class="mt-2">
                {{ item.name }}
              </div>

            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-2 mr-1"
        @click="method === 'edit' ? updatePodcast() : createPodcast()"
      >
        Save changes
      </b-button>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import {
  BCard, BMediaBody, BFormFile, BButton, BCardText, BMediaAside, BLink, BImg, BMedia, BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import presenterStoreModule from '../../presenters/presentersStoreModule'
import podcastsStoreModule from '../podcastsStoreModule'

export default {
  name: 'PodcastCreateEdit',
  components: {
    BCol,
    BRow,
    BCard,
    BFormInput,
    BMediaBody,
    BFormFile,
    BButton,
    BCardText,
    BMediaAside,
    BLink,
    BImg,
    BFormGroup,
    BMedia,
  },
  directives: {
    Ripple,
  },
  props: {
    method: {
      type: String,
    },
  },
  data() {
    return {
      file: null,
      profileFile: null,
      presenterStoreName: 'presenters',
      storeName: 'podcasts',
      presenters: [],
      podcast: {
        Programmers: [],
      },
    }
  },
  computed: {
    programmerIds() {
      if (this.podcast.Programmers.length > 0) {
        return this.podcast.Programmers.map(p => p.id)
      }
      return []
    },
  },
  async  mounted() {
    await this.registerPresenterStore()
    await this.registerStore()
    this.fetchPresenters()
    if (this.method === 'edit') {
      this.fetchPodcast()
    }
  },
  destroyed() {
    if (this.$store.hasModule(this.storeName)) this.$store.unregisterModule(this.storeName)
  },
  methods: {
    async fileSelected(payload) {
      const formData = new FormData()
      formData.append('file', payload)
      formData.append('postid', 2)
      formData.append('acc', 3)
      formData.append('platform', 'webadmin')
      this.isLoading = true

      await axios
        .post('/upload/admin', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.podcast.imageUrl = response.data.url
        })
        .catch(error => {
          console.log(error)
        })

      this.$mount()
    },
    async fetchPresenters() {
      this.presenters = await this.$store.dispatch(`${this.presenterStoreName}/fetchPresenters`, { limit: '10', page: 1, q: '' })
    },
    async fetchPodcast() {
      console.log('qwdwqqwewq')
      this.podcast = await this.$store.dispatch(`${this.storeName}/fetchPodcast`, this.$route.query.id)
    },
    async updatePodcast() {
      try {
        if (this.podcast.Programmers.length === 0 || this.podcast.title === '') {
          this.$bvToast.toast('Please fill all fields.', {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true,
            variant: 'error',
            solid: true,
          })
          return
        }
        this.$store.dispatch(`${this.storeName}/updatePodcast`, this.podcast)
        this.$bvToast.toast('Podcast updated successfuly.', {
          title: 'Success',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'success',
          solid: true,
        })
        setTimeout(() => {
          this.$router.push({ name: 'Podcasts' })
        }, 2000)
      } catch (error) {
        this.$bvToast.toast('Getting an error while updating podcast.', {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'error',
          solid: true,
        })
      }
    },
    createPodcast() {
      try {
        if (this.podcast.Programmers.length === 0 || this.podcast.title === '') {
          this.$bvToast.toast('Please fill all fields.', {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true,
            variant: 'error',
            solid: true,
          })
          return
        }
        this.$store.dispatch(`${this.storeName}/createPodcast`, this.podcast)
        this.$bvToast.toast('Podcast created successfuly.', {
          title: 'Success',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'success',
          solid: true,
        })
        setTimeout(() => {
          this.$router.push({ name: 'Podcasts' })
        }, 2000)
      } catch (error) {
        this.$bvToast.toast('Getting an error while creating podcast.', {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'error',
          solid: true,
        })
      }
    },
    addPresenterIfNotExist(id) {
      if (this.podcast.Programmers.some(item => item.id === id)) {
        this.podcast.Programmers = this.podcast.Programmers.filter(item => item.id !== id)
      } else {
        this.podcast.Programmers.push({ id })
      }
    },
    registerStore() {
      if (!this.$store.hasModule(this.storeName)) this.$store.registerModule(this.storeName, podcastsStoreModule)
    },
    registerPresenterStore() {
      if (!this.$store.hasModule(this.presenterStoreName)) this.$store.registerModule(this.presenterStoreName, presenterStoreModule)
    },
  },
}
</script>
